
import { Link, NavLink } from 'react-router-dom'
import Image from '../../commonComponent/Image/Image'
import './Footer.css'
import { useEffect, useState } from 'react'

import LogoImg from '../../../assets/images/logo-bug-footer@2x.png'

import InstaImg from '../../../assets/images/icon-social-instagram@2x.png'
import FbImg from '../../../assets/images/icon-social-facebook@2x.png'
import YoutubeImg from '../../../assets/images/icon-social-youtube@2x.png'
import PintrestImg from '../../../assets/images/icon-social-pinterest@2x.png'


function Footer(props) {

    
  return (
    <div className="footerSection w-100 flex-centered">
        <div className="footerContentDiv w-80 flex-column">
            <div className="footerTopSection flex flex-between"
                style={{
                    gap: window.innerWidth < 1050 ? '3em' : 'auto',
                }}
            >

                <div
                    style={{
                        gap: window.innerWidth < 1050 ? '1em' : '2em',
                    }}
                    className="footerMenuDiv flex-column gap-2"> 
                        
                        <p className="p-txt" style={{
                                fontSize: '1.2em',
                                fontWeight: '600',
                                letterSpacing: '0.1em'
                        }}>CUSTOMER CARE</p>
                        <div className="flex-column">
                            <Link to="">Order & Shipment</Link>
                            <Link to="">Return & Exchange</Link>
                            <Link to="">Contact  Us</Link>
                            <Link to="">FAQs</Link>
                            <Link to="">Check Gift Card Balance</Link>
                        </div>
                    </div>

                <div
                    style={{
                        borderLeft: window.innerWidth > 1200 ? '2px solid var(--header-dark)' : '',
                        paddingLeft: window.innerWidth > 1200 ? '2.5em': '',
                    
                        gap: window.innerWidth < 1050 ? '1em' : '2em',
                    }}
                    className="footerMenuDiv flex-column gap-2"> 
                        
                        <p className="p-txt" style={{
                                fontSize: '1.2em',
                                fontWeight: '600',
                                letterSpacing: '0.1em'
                        }}>ABOUT US</p>
                        <div className="flex-column">
                            <Link to="">The Designer</Link>
                            <Link to="">Categories</Link>
                            <Link to="">Sustainability</Link>
                            <Link to="">Career</Link>
                        </div>
                    </div>

                <div
                    style={{
                        borderLeft: window.innerWidth > 1200 ? '2px solid var(--header-dark)' : '',
                        paddingLeft: window.innerWidth > 1200 ? '2.5em': '',
                    
                        gap: window.innerWidth < 1050 ? '1em' : '2em',
                    }}
                    className="footerMenuDiv flex-column gap-2"> 
                        
                        <p className="p-txt" style={{
                                fontSize: '1.2em',
                                fontWeight: '600',
                                letterSpacing: '0.1em'
                        }}>LEGAL</p>
                        <div className="flex-column">
                            <Link to="">Corporate Information</Link>
                            <Link to="">Terms & Conditions</Link>
                            <Link to="">Privacy Policy</Link>
                            <Link to="">Cookies Policy</Link>
                        </div>
                    </div>

                <div
                    style={{
                        borderLeft: window.innerWidth > 1200 ? '2px solid var(--header-dark)' : '',
                        paddingLeft: window.innerWidth > 1200 ? '2.5em': '',
                    
                        gap: window.innerWidth < 1050 ? '1em' : '2em',
                    }}
                    className="footerMenuDiv flex-column gap-2"> 
                        
                        <p className="p-txt" style={{
                                fontSize: '1.2em',
                                fontWeight: '600',
                                letterSpacing: '0.1em'
                        }}>NEED HELP?</p>
                        <div className="flex-column">
                            <Link to="mailto:contact@akritibyshakun.com">Email Us at contact@akritibyshakun.com</Link>
                            <Link to="https://wa.me/919876543210">Whatsapp at 9876543210</Link>
                            <Link to="https://goo.gl/maps/KGrnu9gyKmuXjiEDA">Store Locator</Link>
                            {/* <Link to="">Sitemap</Link> */}
                        </div>
                    </div>
            </div>
            
            <div className="footerBottomSection flex">
                <div className="footerBottomDiv flex-column gap-2 w-100"
                    style={{
                        width: window.innerWidth > 1200 ? '40%' : 
                            window.innerWidth > 1050 ? '70%' :
                            '100%',
                        alignItems: window.innerWidth > 1050 ? 'left' : 'center',
                    }}
                >
                    {/* <h2 className="heading-txt" style={{textAlign: 'center'}}>{props.bottomHeading}</h2> */}
                    <div className='imgLogoDiv w-100 flex'>
                        <Image 
                            style={{textAlign: 'left'}} 
                            className="txtLogoImg" 
                            src={"../../images/logo-footer-abs@2x.png"} 
                            alt={props.altBottomImg} 
                            width={window.innerWidth > 1200 ? '70%': '100%'} 
                            height="auto"/>
                    </div>
                    <p className="p-txt" style={{
                        textAlign: window.innerWidth > 1050 ? 'left' : 'center', 
                        fontSize: '1.2em',
                        
                        }}>{`© Akriti By Shakun. | All Rights Reserved.`}</p>
                </div>
                <div className="footerBottomDiv w-20">
                    <Image className="logoImg" src={LogoImg} alt="Logo"/>
                </div>
                <div style={{
                        alignItems: window.innerWidth >= 970 ? 'flex-end' : 'center',
                        width: window.innerWidth >= 1200 ? '30%' : 
                            window.innerWidth >= 970 ? '50%' :
                            '100%',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                    }} className="footerBottomDiv flex-column w-50 gap-2">
                    <h2 className="p-txt" style={{textAlign: 'left', 
                                                fontWeight: '500',
                                                fontSize: '1.2em',
                                                letterSpacing: '0.1em'
                                                }}>
                    {`FOLLOW & SUBSCRIBE`}</h2>
                    <div className="bottomFooterSocial flex-between">
                        <NavLink to="https://www.instagram.com/akritibyshakun/"><Image src={InstaImg} width={25} height={25} alt=""/></NavLink>
                        <NavLink to="https://www.facebook.com/akritibyshakun/"><Image src={FbImg} width={25} height={25} alt=""/></NavLink>
                        <NavLink to="https://in.pinterest.com/AkritiByShakun/"><Image src={PintrestImg} width={25} height={25} alt=""/></NavLink>
                        <NavLink to="https://youtube.com/@akritibyshakun631"><Image src={YoutubeImg} width={25} height={25} alt=""/></NavLink>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Footer