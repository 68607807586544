import './Button.css';

function Button(props) {

    // const buttonCLass = `${} `;

    return (
        <div className="buttonSection">
            <button 
                className={`btn ${props.variant}`} 
                style={props.btnClass} 
                onClick={props.onClick}>{props.title}</button>
        </div>
    )
}

export default Button