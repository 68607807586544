import React, { useEffect, useState } from 'react'
import { Link, Navigate, NavLink, useNavigate } from 'react-router-dom'
import RootLayout from '../../Layout'
import './PretProduct.css'

import { RiArrowDropDownFill } from '@react-icons/all-files/ri/RiArrowDropDownFill.esm';



import Button from '../../commonComponent/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getFavoriteParoducts, getProduct} from '../../../store/productSlice';
import ProductCard from '../../commonComponent/Product/Product';
import productService from '../../../services/axios/product';

function PretProduct() {

    const dispatch = useDispatch();

    const [productList, setProductList] = useState([]);


    useEffect(() => {
        dispatch(getFavoriteParoducts({}))
    }, [])

    useEffect(() => {
        productService.list({category:'Pret',pageSize:100})
            .then((resp) => {
                console.log("Product List", resp?.data);
                setProductList(resp?.data?.response?.list);
            })
    }, [])

    return (
        <RootLayout >
            <div className="pretProductSection w-100 flex-centered flex-column gap-1">
                <div className="pretProductHeader flex flex-column gap-3">
                    {window.innerWidth > 768 ? <><br /><br /></> : ""}
                    <div className="pretProductHeading">
                        <p>CATEGORIES / PRET </p>
                        <h2>Shop All</h2>
                    </div>
                    <div className="pretProductHeaderBottom flex flex-between">
                        <div className="pretProductFilter">
                            <div className='pretProductFilterTxt flex'>
                                <p>FILTERS </p>
                                <RiArrowDropDownFill style={{ fontSize: '1.5em' }} />
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Filter 1</NavLink></li>
                                <li><NavLink>Filter 2</NavLink></li>
                                <li><NavLink>Filter 3</NavLink></li>
                                <li><NavLink>Filter 4</NavLink></li>
                            </ul>
                        </div>
                        <div className="pretProductSortBy">
                            <div className='pretProductSortByTxt flex'>
                                <p>SORT BY </p>
                                <RiArrowDropDownFill style={{ fontSize: '1.5em' }} />
                            </div>
                            <ul className="pretProductDropMenu">
                                <li><NavLink>Sort By 1</NavLink></li>
                                <li><NavLink>Sort By 2</NavLink></li>
                                <li><NavLink>Sort By 3</NavLink></li>
                                <li><NavLink>Sort By 4</NavLink></li>
                            </ul>
                            <div className="pretProductGrid">

                            </div>
                        </div>

                    </div>
                </div>
                <div className="pretProductContent flex-centered flex-column gap-3">

                    <div className='flex-centered w-100'>
                        {productList?.map((product) => <ProductCard 
                                        details={product} 
                                        key={product?.id} 
                                    />)
                        }
                    </div>


                    {/* <div className='flex-centered productBtnDiv '>
                        <Button
                            title="+ VIEW MORE"
                            variant="btn-dark"
                        />
                    </div> */}
                    <br/>
                    <br/>
                    <br/>
                </div>

            </div>
        </RootLayout>

    )
}

export default PretProduct