import React, { Children } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import ForgotPass from '../app/Authentication/ForgotPass/ForgotPass'
import Login from '../app/Authentication/Login/Login'
import Signup from '../app/Authentication/Signup/Signup'
import Cart from '../app/Cart/Cart'
import Favorite from '../app/Favorite/Favorite'
import MyOrderDetail from '../app/MyProfile/MyOrderDetail/MyOrderDetail'
import MyProfile from '../app/MyProfile/MyProfile'
import ProfileDetailUpdate from '../app/MyProfile/ProfileDetailUpdate/ProfileDetailUpdate'
import NewArrivals from '../app/NewArrivals/NewArrivals'
import Page from '../app/Page'
import BridalProduct from '../app/ProductCategories/Bridal/BridalProduct'
import MenProduct from '../app/ProductCategories/Men/MenProduct'
import PretProduct from '../app/ProductCategories/Pret/PretProduct'
import ProductDetail from '../app/ProductDetail/ProductDetail'
import CartFailed from '../app/Cart/CartFailed'
import CartSuccess from '../app/Cart/CartSuccess'
import CartRespHandler from '../app/Cart/CartRespHandler'
const AllRouters = () => {
    const router = [
        {path: '/', element: <Page />},

        {path: '/login', element: <Login />},
        {path: '/sign-up', element: <Signup />},
        {path: '/reset-pass', element: <ForgotPass />},
        {path: '/profile/', element: <MyProfile />},
        {path: '/my-profile/my-order-detail/:id', element: <MyOrderDetail />},
        {path: '/my-profile/my-order-detail/edit', element: <ProfileDetailUpdate />},
        

        {path: '/new-arrivals', element: <NewArrivals />,
          children: [
            
          ]
        },
        {path: '/product-detail/:id', element: <ProductDetail />},
        {path: '/categories/pret', element: <PretProduct />},
        {path: '/categories/bridal', element: <BridalProduct />},
        {path: '/categories/men', element: <MenProduct />},
        {path: '/cart', element: <Cart />},
        {path: '/cart/failed', element: <CartFailed />},
        {path: '/cart/success', element: <CartSuccess />},
        {path: '/cart/resp-handler', element: <CartRespHandler />},
        {path: '/favorite', element: <Favorite />},
        

    ]

    return useRoutes(router)
}

function MainRouter() {

  return (
    <div >
        <BrowserRouter basename='/'>
            <AllRouters />
        </BrowserRouter>
    </div>
  )
}

export default MainRouter