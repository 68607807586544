import React, { useEffect, useState } from 'react'
import './MyProfile.css'
import RootLayout from '../Layout'

import CustImg from '../../assets/images/person.jpg'

import ProductImg1 from '../../assets/images/product/product001.jpg'
import ProductImg2 from '../../assets/images/product/product002.jpg'
import ProductImg3 from '../../assets/images/product/product003.jpg'
import ProductImg4 from '../../assets/images/product/product004.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { getDetails } from '../../store/customerSlice'
import { useNavigate, useNavigation } from 'react-router-dom'
import BackPageCompo from '../commonComponent/BackPageCompo/BackPageCompo'
import { getLogout } from '../../store/authSlice'
import Button from '../commonComponent/Button/Button'



const OrderCard = (props) => {

    const navigate = useNavigate()

    return (
        <div className="orderCardSection flex-column" onClick={()=> navigate(`/my-profile/my-order-detail/${props.id}`)}>
            <div className='orderCardImg'>
                <div className='orderCardPriceAbs'>&#8377; {props.price}</div>
                <div className='orderCardImgAbs'>
                    <img src={props.img} />
                </div>
            </div>

            <div className='orderCardTxt'>
                <h2>{props.productName}</h2>
                <p>Quantity - {props.quantity}</p>
            </div>
        </div>
    )
}

function MyProfile() {

    const dispatch = useDispatch();
    const navigate=useNavigate()

    const {profile}=useSelector((store) => store.customer);
    const {isLoggedIn}=useSelector((store) => store.auth);


    return (<>
        <RootLayout>
            <div className='myProfileSection flex-centered flex-column gap-2 w-100'>
                <BackPageCompo 
                    href="/"
                    title="Profile"
                />
                <div className='flex-column gap-2 w-80' >
                    <div className='flex gap-2 w-100'>
                        <div className='myProfileOrdersSection w-70 flex-column flex-centered gap-1'>
                            <h2>My Orders</h2>
                            <hr/>
                            <div className='myprofileOrderDiv w-100 gap-1'>
                                <OrderCard 
                                    img={ProductImg1}
                                    price='10,000.00'
                                    productName="Akriti Bridal Lehnga"
                                    quantity="1"
                                    id="50"
                                />

                                <OrderCard 
                                    img={ProductImg2}
                                    price='10,000.00'
                                    productName="Akriti Bridal Lehnga"
                                    quantity="1"
                                    id="50"

                                />
                                <OrderCard 
                                    img={ProductImg2}
                                    price='10,000.00'
                                    productName="Akriti Bridal Lehnga"
                                    quantity="1"
                                    id="50"

                                />
                                <OrderCard 
                                    img={ProductImg2}
                                    price='10,000.00'
                                    productName="Akriti Bridal Lehnga"
                                    quantity="1"
                                    id="50"

                                />
                            </div>
                            
                        </div>

                        <div className='myProfileInfo w-30 flex-column gap-1'>
                            <div className='w-100 flex-between'>
                                <h2>My Profile</h2>
                                <Button 
                                    title="Logout"

                                    btnClass={{
                                        padding: '0.5em 1em',
                                        border: 'none',
                                        fontSize: '1em',
                                        color: 'grey',
                                        letterSpacing: '0.05em'
                                    }}
                                    onClick={()=>dispatch(getLogout(navigate))}
                                />
                            </div>
                            <hr/>
                            <div className='flex-column gap-2 flex-centered'>
                                <div className='myProfileInfoImg'>
                                    <img src={CustImg} width={150} />
                                </div>
                                <div className='myProfileInfoTxt flex-column gap-1'>
                                    <h3>{profile?.name}</h3>
                                    <p>{profile?.email}</p>
                                    <p>+91 {profile?.mobile}</p>
                                    {/* <p onClick={()=>dispatch(getLogout(navigate))}
                                        style={{
                                            
                                            cursor: 'pointer',
                                            color: '',
                                        }}
                                    >Logout</p> */}

                                </div>
                                
                            </div>

                            <hr/>

                            <div className='myProfileInfoTxtAddress flex-column gap-1'>
                                <div className=''>
                                    <h3>Shipping Address</h3>
                                    <p>{profile?.address1} {profile?.address2},</p>
                                    <p>{profile?.city}, {profile?.state}</p>
                                    <p>{profile?.zipcode}, {profile?.country}</p>
                                </div>
                                <div className=''>
                                    <h3>Billing Address</h3>
                                    <p>{profile?.billing_address}</p>
                                    <p>{profile?.billing_city}, {profile?.billing_state}</p>
                                    <p>{profile?.billing_zipcode}, {profile?.billing_country}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </RootLayout>
    </>)
}

export default MyProfile