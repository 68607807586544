
import './page.module.css'
import './globals.css'
import {React} from 'react'

import Navbar from './components/Navbar/Navbar';
import Banner from './components/Banner/Banner';
import FeatureCategories from './components/FeaturedCat/FeatureCategories';
import FollowUs from './components/FollowUs/FollowUs';
import ShortBanner from './components/ShortBanner/ShortBanner';
import Footer from './components/Footer/Footer';
import Button from './commonComponent/Button/Button';
import AppointBanner from './components/AppointBanner/AppointBanner';


import HeroBannerBig from '../assets/images/hero-slider.png'
import HeroBannerSmall from '../assets/images/heroBannerSmall.jpg'



export default function Home() {

  return (
    <main className="">
      <Navbar />
      <Banner 
        img={window.innerWidth > 650 ? HeroBannerBig : HeroBannerSmall}
        subHeading="MAGICAL MOMENTS & PRICEOUS PIECES"
        btnTitle="SHOP NOW"

        bannerContainerAlign="flex-start"
        textDivWidth="40em"
      />
      <FeatureCategories />
      <AppointBanner 
        logoImg="/images/logo-bug-appointment@2x.png"
        logoImgWidth="160"
        logoImgHeight="160"
        title="BOOK AN APPOINTMENT"
        subTitle="We are available for online as well as in-store appointments"
        MenuList={[
          {href:"", menu:"Consultation"},
          {href:"", menu:"Customisation"},
          {href:"", menu:"Measurements"},
        ]}

        btnTitle="BOOK NOW"
      />

      <FollowUs />

      <ShortBanner 
        src="/images/banner_001.jpg"
        title="SIGN UP & SAVE 10% ON YOUR PURCHASE"
        input={true}
        hint="Enter your email here"
        name="email"
        btnTitle=">"
      />

      <Footer />

        {/* <h1 className={styles.headA}>Home page</h1>
        <br/>
        <button onClick={()=>router.push('/login')}>Go to about by router button</button> */}
    </main>
  )
}
