

import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Image from "../../commonComponent/Image/Image";
import './Navbar.css';
// import {ReactComponent as MenuBox} from "../../../assets/svg/menu-2.svg"
import MenuBox from '../../../assets/images/icon-menu@2x.png'

import {FiX} from '@react-icons/all-files/fi/FiX.esm'
import { useSelector } from "react-redux";

import FavImg from '../../../assets/images/icon-favourite @2x.png'
import UserImg from '../../../assets/images/icon-user@2x.png'
import SearchImg from '../../../assets/images/icon-search@2x.png'
import BagImg from '../../../assets/images/icon-bag@2x.png'

const TopHeader = (props) => {
    return (
        <div className={`topHeaderSection w-100 flex-centered`}>
            <div className={`topHeaderContent w-100 flex-centered`}>
                <p 
                    style={{
                        fontSize: window.innerWidth > 400 ? '0.7em': '0.55em',
                    }}
                >{props.text}</p>
            </div>
        </div>
    );
};

const MidHeader = (props) => {
    const cartTotal = 10;
    const {Icons} = props;

    const { cartList } = useSelector(store => store.order);
    const { isLoggedIn } = useSelector(store => store.auth);
    

    return (
        <div className={`midHeaderSection flex-centered w-100`}>
            <div className={`midHeaderContent flex-between`}
                style={{
                    width: window.innerWidth > 768 ? '80%' : '100%',
                    padding: window.innerWidth > 768 ? '0.5em 1em': '0.5em 0em',
                }}
            >
                <div className={`midHeaderContainer flex`}>
                    <div className={`midHeaderImageDiv`}>
                        <button id="menuBtn" 
                            style={{
                                background: 'transparent', 
                                border: 'none', 
                                outline: 'none', 
                                minWidth: '40px',
                                
                            }} type="button" onClick={props.onClick}
                        >
                            <Image src={MenuBox} 
                                width={
                                    window.innerWidth < 768 ? "25px" : 40
                                } 
                                height={
                                    window.innerWidth < 768 ? "25px" : 40} alt="menu box" />
                            
                        </button>
                    </div>
                    <div className={`midHeaderText flex-centered`}>
                        <p><Link to="">AKRITIBYSHAKUN.COM</Link> | <Link to="">CONTACT US</Link></p>
                    </div>
                </div>

                <div className={`midHeaderContainer`} style={{
                    // display: window.innerWidth < 800 && !props.isToggle ? 'none' : 'flex',
                    display: window.innerWidth < 800 ? 'flex' : 'flex',
                }}>
                    <div className={`midHeaderImageDiv`}>

                        {(isLoggedIn)?<Link to="/favorite">
                            <Image src={FavImg} alt="" 
                                width={
                                    window.innerWidth > 768 ? 20 : 15} 
                                height={
                                    window.innerWidth > 768 ? 20 : 15}/>

                        </Link>:<></>}
                        
                        <Link to={(isLoggedIn)?'/profile':'/login'}>
                            <Image src={UserImg} alt="" 
                                width={
                                    window.innerWidth > 768 ? 20 : 15} 
                                height={
                                    window.innerWidth > 768 ? 20 : 15}/>

                        </Link>
                        <Link to="">
                            <Image src={SearchImg} alt="" 
                                width={
                                    window.innerWidth > 768 ? 20 : 15} 
                                height={
                                    window.innerWidth > 768 ? 20 : 15}/>

                        </Link>
                        <Link to="/cart">
                            <Image src={BagImg} alt="" 
                                width={
                                    window.innerWidth > 768 ? 20 : 15} 
                                height={
                                    window.innerWidth > 768 ? 20 : 15}/>
                            <div className="navbarCartCount">
                                <p >{cartList?.length}</p>
                            </div>
                        </Link>
                        
                    </div> 
                </div>
            </div>
        </div>
    );
};


const BottomHeader = (props) => {



    return (
        <div className="bottomHeaderSection flex-column w-100 flex-centered">
            <div className="bottomHeaderContent" 
                style={{
                    marginBottom: window.innerWidth > 600 ? '20px': '10px',
                    
                }}
            >
                {/* <h2 className="heading-txt">{props.heading}</h2> */}
                <NavLink to="/" className="flex-centered"><Image 
                    src={props.logoImg} 
                    width={window.innerWidth > 768 ? "30em" : "70%"} 
                    height={window.innerWidth > 768 ? "auto" : "100%"}
                    />
                </NavLink>
            </div>
            
            <div className="bottomHeaderMenuDiv">
                <ul className="flex">
                    {props?.MenuList?.map((item,i) =>{
                        return <div className="flex-centered gap-2" key={i}>{i > 0 ? <p> | </p> : null} <Link to={item.href} >{item.menu}</Link></div>
                    })}
                </ul>
            </div>
        </div>
    )
};

const Navbar = () => {
    
    const [isToggle, setIsToggle] = useState(false);

    const handleToggle = ()=> {
        setIsToggle((prevState) => !prevState);
        
    }

    return (
        <div className="headerSection">
            <TopHeader text="SHIPPING WORLDWIDE | CALL US AT 9876543210"/>
            {/* <div className="menuBtnDiv">
                <button id="menuBtn" style={{background: 'transparent', border: 'none', outline: 'none'}} type="button" onClick={handleToggle}>
                    <Image src="/svg/menu-1.svg" width={30} height={30} alt="" />
                </button>
            </div> */}

            <MidHeader 
                isToggle={isToggle}
                onClick={handleToggle}
            />
            
            <div className="toggleMenuSection"
                style={{
                    height: isToggle ? 'auto' : '0em',
                    animation: 
                        isToggle ? 'heightFullToZero 1s linear 0': 
                        'heightFullToZero 1s linear 0',
                    // transition: "height 500ms linear 0",
                }}
            >
                <div className="toggleMenuContainer">
                    <div className="toggleMenuHeader flex-between flex-centered">
                        <p className="toggleMenuHeaderTxt">AKRITIBYSHAKUN.COM</p>
                        <div className="toggleMenuHeaderIcon flex-centered" onClick={handleToggle}><FiX /></div>
                    </div>
                    <br/>
                    <div className="toggleMenuContent">
                        <div className="w-100 flex-column">
                            <NavLink to=""><p>New Arrivals</p></NavLink>
                            
                            <hr/>
                        </div>

                        <div className="w-100 flex-column">
                            <h3>PRET</h3>
                            <NavLink to=""><p>Occasional Wear</p></NavLink>
                            <NavLink to=""><p>Luxury Pret</p></NavLink>
                            <hr/>
                        </div>

                        <div className="w-100 flex-column">
                            <h3>BRIDAL</h3>
                            <NavLink to=""><p>Wedding Couture</p></NavLink>
                            <hr/>
                        </div>

                        <div className="w-100 flex-column">
                            <h3>BRAND</h3>
                            <NavLink to=""><p>About The Designer</p></NavLink>
                            <NavLink to=""><p>Brand Journey</p></NavLink>
                            <NavLink to=""><p>Press & Media</p></NavLink>
                            <hr/>
                        </div>

                        <div className="w-100 flex-column">
                            <NavLink to=""><p>Favourite</p></NavLink>
                            
                            <hr/>
                        </div>

                        <div className="w-100 flex-column">
                            <NavLink to=""><p>Sale</p></NavLink>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* <br/> */}
            <div className="" style={
                {
                    // display: window.innerWidth < 800 && !isToggle ? 'none' : 'flex',
                    display: window.innerWidth < 800 ? 'flex' : 'flex',
                    marginTop: window.innerWidth < 800 ? '20px' : '0',
                }
            }>
            
            <BottomHeader 
                // heading="AKRITI BY SHAKUN"
                logoImg="/images/logo-main@2x.png"
                
                MenuList={[
                    {href: '/categories/pret', menu: 'PRET'},
                    {href: '/categories/bridal', menu: 'BRIDAL'},
                    {href: '/categories/men', menu: 'MEN'},
                    // {href: '/new-arrivals', menu: 'NEW ARRIVALS'},
                ]}
            />
            </div>
        <br/>
        </div>
    );
};

export default Navbar;
