import Button from '../../commonComponent/Button/Button';
import './Banner.css'

import { useEffect, useState } from 'react';
import Image from '../../commonComponent/Image/Image';

function Banner(props) {

    const [width, setWidth] = useState(0)

    useEffect(()=>{
        setWidth(window.innerWidth);
    }, [])
    
    const divResponsive = {

    }

    const bannerContainer = {
        justifyContent: props.bannerContainerAlign,
    }
    
    const bannerText = {
        minWidth: 
            width >= 1200 ? props.textDivWidth :  
            width >= 780 ? "30em" : 
            width >= 600 ? "21em" :
            width >= 500 ? "17em" :
            width >= 400 ? "50%" :
            width >= 300 ? "60%" :
            width <= 300 ? "80%" :

            "100%",
        maxWidth: 
            width >= 1200 ? props.textDivWidth :  
            width >= 780 ? "30em" : 
            width >= 600 ? "21em" :
            width >= 500 ? "17em" :
            width >= 400 ? "50%" :
            width >= 300 ? "60%" :
            width <= 300 ? "80%" :

            "100%",
    }

    const imageDiv = {
        filter: props.blur,
    }
    return (
        <div className="bannerSection">
            <div style={bannerContainer} className="bannerContainer">
                <div style={imageDiv} className="bannerImgDiv">
                    <Image 
                        src={props.img}  
                        width="100%" 
                        height="100%" 
                        alt="Image"/>
                </div>
                <div style={bannerText} className="bannerText flex-column ">
                    <h3>{props.heading}An <i>Iternal</i> Romance</h3>
                    
                    <p>{props.subHeading}</p>
                    
                    <Button variant="btn-light" title={props.btnTitle}/>
                </div>
            </div>
        </div>
    )
}

export default Banner