import React from 'react'

import {BiArrowBack} from '@react-icons/all-files/bi/BiArrowBack.esm'
import { NavLink, useNavigate } from 'react-router-dom'

const BackPageCompo = (props) => {

    // const history = useHistory();
  const navigate = useNavigate();

  return (
    <div className='BackPageCompoSection flex-centered ' style={{
        padding: '1.5em',
        background: 'white',
        textAlign: 'left',
        borderRadius: '0.5em',
        position: 'relative',
    }}>
        <NavLink style={{
          position: 'absolute',
          left: '1em',

        }} to={props.href}><BiArrowBack /></NavLink>

        <p style={{
          fontSize: '1em ',
          fontWeight: '450',
          textAlign: 'center',
          padding: '0 1em'
        }}>{props.title}</p>

        {/* <div className=''>
          {}
        </div> */}
    </div>
  )
}

export default BackPageCompo