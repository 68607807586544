
import Button from '../Button/Button'
import Image from '../Image/Image'
import './Card.css'

function Card(props) {

    const cardSection = {
        // maxWidth: props.maxWidth,
    }

    const cardImgDiv = {
        // maxWidth: props.maxWidth,
        // minHeight: props.imgMaxHeight,
    }
return (
    <div style={cardSection} className="cardSection">
        {props.src && (
            <div style={cardImgDiv} className="cardImgDiv flex-centered">
                <div  className="cardAbsDiv flex-centered">
                    <Image src={props.src} width={props.imgWidth} height={props.imgHeight} alt="Banner" />
                </div>
            </div>
        )}
        {props.title || props.desc || props.btnTitle ? ( 
            <div className="cardTextDiv flex-centered flex-column">
                {props.title && (<h2 className='heading-txt'
                    style={{
                        letterSpacing: '0'
                    }}
                >{props.title}</h2>)}
                {props.desc && (<p className='p-txt' style={{textAlign: 'center', fontSize: '1em'}}>{props.desc}</p>)}
                {/* <br/> */}
                {props.btnTitle && (<Button variant="btn-dark" title={props.btnTitle} />)}
            </div>
        ) : ""}
    </div>
)
}

export default Card